import { CSSTransition } from 'react-transition-group';

export default ({
  children,
  marginTop,
  marginBottom,
  minHeight,
  background = 'none',
  className,
  style,
  animate = true
}) => (
  <div className={className} style={style}>
    {animate ? (
      <CSSTransition classNames="section-anim" appear in timeout={200}>
        <div className={`section ${background} customStyle`}>{children}</div>
      </CSSTransition>
    ) : (
      <div className={`section ${background} customStyle`}>{children}</div>
    )}

    <style jsx="true">{`
      .customStyle {
        margin-top: ${marginTop};
        margin-bottom: ${marginBottom};
        min-height: ${minHeight};
      }
    `}</style>
  </div>
);
